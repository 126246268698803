import client from "./client";

const getCompanion = (id) => {
  return client
    .get(`/companion/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const getCompanions = (filter) => {
  return client
    .get(`/companions`, filter)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => error.response);
};

const createCompanion = (data) => {
  return client
    .post("/companion", data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const updateCompanion = (id, data) => {
  return client
    .put(`/companion/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const deleteCompanions = (data) => {
  return client
    .put(`/companion`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const companionAPI = {
  getCompanion,
  createCompanion,
  updateCompanion,
  deleteCompanions,
  getCompanions,
};

export default companionAPI;
